import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './404.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  public currentUrl: string = null;
  public errorUrl: string = null;

  constructor(private readonly router: Router) {
    this.currentUrl = this.router.url;
    const navigation = this.router.getCurrentNavigation();
    // errorUrl param sent from the error.interceptor
    this.errorUrl = navigation?.extras?.state?.errorUrl;
  }
}
