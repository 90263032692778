import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fr-internal-server-error',
  templateUrl: './500.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalServerErrorComponent {
  public errorUrl = '';

  constructor(private readonly router: Router) {
    const navigation = this.router.getCurrentNavigation();
    // errorUrl param sent from the error.interceptor
    this.errorUrl = navigation?.extras?.state?.errorUrl;
  }
}
