import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { NOTIFICATIONS_OVERLAY_CONFIG } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationOverlayService {
  private readonly defaultOverlayConfig: OverlayConfig = NOTIFICATIONS_OVERLAY_CONFIG;

  private readonly overlayRef: OverlayRef[] = [];
  private readonly containerRef$: BehaviorSubject<{
    viewContainerRef: ViewContainerRef;
    injector: Injector;
  } | null> = new BehaviorSubject(null);
  private readonly modalResult$: Subject<any> = new Subject();

  constructor(private readonly overlay: Overlay) {}

  public registerCurrentContainerRef(viewContainerRef: ViewContainerRef, injector: Injector) {
    this.containerRef$.next({
      viewContainerRef,
      injector,
    });
  }

  public unregisterCurrentContainerRef() {
    this.containerRef$.next(null);
  }

  public displayNotification<T>(component: ComponentType<T>, dataProperty?: string, data?: any) {
    // Unregistering modal before opening prevents issues with the containerRef reference not being fired properly
    // in certain scenarios when this modal-overlay.service was already injected
    this.unregisterCurrentContainerRef();
    return this.containerRef$.pipe(
      take(1),
      switchMap((containerRef) => {
        this.closeNotifications(null);

        this.overlayRef.push(this.overlay.create(this.defaultOverlayConfig));

        const instance = this.overlayRef[this.overlayRef.length - 1].attach(
          new ComponentPortal(component, containerRef?.viewContainerRef, containerRef?.injector)
        ).instance;

        if (dataProperty && data) {
          instance[dataProperty] = data;
        }

        return this.modalResult$.pipe(take(1));
      })
    );
  }

  public closeNotifications(modalResult: any) {
    this.modalResult$.next(modalResult);

    if (this.overlayRef.length) {
      this.overlayRef.pop().dispose();
    }
  }
}
