import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '@shared/services';
import { INewsModel } from '../models/i-news.model';
import { AccountTypeEnum } from '@app/shared/enums';
import { newsAccountTypeToApiUrl } from '@app/features/auth';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private readonly genericHttpService: GenericHttpService) {}

  public getUserNews(accountType: AccountTypeEnum): Observable<INewsModel[]> {
    return this.genericHttpService.get<INewsModel[]>(`news/${newsAccountTypeToApiUrl(accountType)}`);
  }

  public markAsReadNews(newsId: string[]): Observable<any> {
    return this.genericHttpService.post(`news/mark-read`, { newsId }, true);
  }

  public markAsSeenNews(newsId: string[]): Observable<any> {
    return this.genericHttpService.post(`news/mark-seen`, { newsId }, true);
  }
}
