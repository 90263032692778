export const MAX_LENGTH = {
  text: 255,
  smallText: 100,
  bigText: 600,
  xlLargeText: 5000,
  textareaText: 450,
};
const MAX_INTEGER_DIGITS = 13;
const MAX_NUMBER = +('9'.repeat(MAX_INTEGER_DIGITS) + '.99');

export const NUMBER_LIMITS = {
  max: MAX_NUMBER,
  min: -MAX_NUMBER,
};

export const EMAIL_REGEX = '^(?=.{1,255}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,63}$';
export const STRICT_MIN = 'strictMin';
export const STRICT_MAX = 'strictMax';
export const NUMBER_FORMAT = 'numberFormat';
export const INTEGER_NUMBER_FORMAT = 'integerNumberFormat';
export const EQUAL_FORM_ERROR = 'equalValue';
export const GREATER_FORM_ERROR = 'greaterValue';
export const WHITE_SPACE = 'whiteSpace';
export const MIN_AMOUNT = 'minAmount';
export const RELATIVE_PATH_REGEX = /^(?!\/)(?!.*\.)[a-zA-Z0-9-_]+(\/[a-zA-Z0-9-_]+)*(\/)?(\?[a-zA-Z0-9-_=&]*)?$/;
